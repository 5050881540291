<template>
  <div class="invited">
    <div class="info-box">
      <div class="">
        <van-cell-group inset>
          <van-cell title="当前手机号" :value="phone" />
          <van-field v-model="newphone" maxlength='11' placeholder="请输入新的手机号码" />
          <van-field v-model="code" maxlength='6' placeholder="请输入验证码">
            <template #button>
              <span class="sendinfo" @click="getcode">{{text}}</span>
            </template>
          </van-field>
        </van-cell-group>
      </div>
    </div>

    <div class="btn-box">
      <div class="btn-inner">
        <div class="btn-item" @click="sysUserUpdatePhone()">保 存</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Cell, CellGroup, Field } from "vant";
export default {
  name: "index",
  data() {
    return {
      phone: this.$route.query.urlVal,
      id: this.$route.query.id,

      newphone: "",
      code: "",
      text: "获取验证码",
    };
  },
  methods: {
    // 获取验证码
    async getcode() {
      var reg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
      if (this.text != "获取验证码") {
        return false;
      }
      if (!reg.test(this.newphone)) {
        return Toast("正确输入手机号码");
      }
      console.info(this.newphone);

      let params = {
        phone: this.newphone,
        userId: this.id
      };
      Toast.loading();
      await this.$post("/h5/weChat/sysUserUpdatePhoneGetCaptcha", params).then((res) => {
        if (res.code == "0") {
          Toast('已发送至手机')
          this.countdown();
        } else {
          Toast(res.message);
        }
      });
      
     
    },
    // 倒计时
    countdown() {
      this.text = "60s";
      let n = 59;
      let timer = setInterval(() => {
        let time = n--;
        this.text = `${time}s`;

        if (n == 0) {
          clearInterval(timer);
          this.text = "获取验证码";
        }
      }, 1000);
    },


    // 根据code获取openId
    async sysUserUpdatePhone() {
      let params = {
        newPhone: this.newphone,
        checkCode: this.code,
        userId: this.id
      };
      await this.$post(`/h5/weChat/sysUserUpdatePhone`, params).then((res) => {
        if (res.code == "0") {
          this.$router.go(-1)
        }
      });
    },
  },
  created() {
  },
  mounted() {},
  components: {
    [Toast.name]: Toast,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
  },
};
</script>

<style lang="scss" scoped>
.invited {
  background: #f4f4f4;
  min-height: 100vh;
  overflow: hidden;
}
.info-box {
  margin: 15px 0;
  .van-cell {
    padding: 15px;
    font-size: 16px;
  }
}
.c-4D4D4D {
  color: #4d4d4d;
}
.w40 {
  flex: 0 0 40%;
}
.w55 {
  flex: 0 0 55%;
}
.sendinfo {
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #526eff;
}
</style>